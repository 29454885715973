.sessionize-loader,
.sz-speakers--wall,
.sz-root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}
.sz-speaker {
  padding: 0 32px 32px 0;
  width: 200px;
}
.sz-speaker__name {
  @apply font-body text-xl;
  padding: 4px 0;
}
.sz-speaker__tagline {
  @apply text-sm;
}
.sz-cssgrid__track-label {
  display: none;
  visibility: hidden;
}
