.faq_page {
  > #faqs {
    @apply x-padded relative mb-40 md:mb-0 lg:mb-0 pt-8;

    > h1 {
      @apply w-full text-left;
    }
    > ul#table-of-contents {
      @apply mt-4;
    
      list-style-type: none;
      > li {
        @apply m-0 px-2 py-1 border-l-2 border-black hover:border-pink-600 hover:border-l-4;
      }
    } 
  }
  
  & #miscellaneous {
    /* The decorated element just before the footer */
    padding-bottom: 10rem;
  }
}
