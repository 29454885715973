main {
  margin: 2rem auto 4rem;
  max-width: 65rem;
  min-height: calc(100vh - 200px);
  border-radius: 3px;
}

a {
  color: var(--text-primary);

  &:hover,
  &:focus {
    color: var(--text-primary-hover);
    transform: none;
  }
}

ul {
  list-style-type: disc;
  list-style-position: inside;
}

ol {
  list-style-type: decimal;
  list-style-position: inside;
}

* {
  border-style: solid;
  border-color: var(--border-primary)
}