/* Menu component */
nav.menu-component {
  /* max-width: 275px; */
  background-color: var(--base-bg-base);
  z-index: 20;

  &.notifications {
    width: 256px;
    max-width: 375px;

    @media (min-width:640px) {
      width: 384px;
    }
  }

  /* Main list */
  > ul.menu-list {
    @apply flex gap-4 text-lg lg:items-center;
    list-style-type: none;
    margin: 1rem 0 0 0;
    padding: 0px;

    > li.menu-section {
      margin: 0;
      padding: 8px;

      &:not(:last-child) {
        border-bottom: 1px solid var(--base-border-tertiary);
      }

      /* Section headers */
      > h2.menu-section-heading {
        font-size: 14px;
        color: var(--base-text-tertiary);
        padding: 12px 24px 4px 24px;
        font-weight: 600;
        line-height: 20px;
        margin: 0px;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:has(> a) {
          padding: 0px;
        }

        > a {
          color: var(--base-text-tertiary);
          display: block;
          padding: 12px 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;

          &:hover {
            color: var(--base-text-secondary);
          }
        }
      }

      &:has(> h2.menu-section-heading > a):hover {
        background-color: var(--base-bg-high);
      }

      > ul.menu-sub-list {
        list-style-type: none;
        margin: 0px;
        padding: 0px;

        li {
          padding: 0px 0px;

          a,
          form button {
            align-items: center;
            color: var(--base-text);
            display: block;
            font-size: 14px;
            font-weight: 400;
            gap: 8px;
            line-height: 24px;
            overflow: hidden;
            padding: 6px 24px;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:has(*) {
              display: flex;
            }

            &:hover {
              background-color: var(--base-bg-high);
              color: var(--base-text-secondary);
            }

            >span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 90%;
            }

            svg {
              color: var(--base-icon);
              height: 20px;
              width: 20px;
            }

            * + svg {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}

header > .nav_wrapper {
  @apply border-b-2 border-black mx-auto max-w-screen-xl pt-6 pb-5 flex flex-wrap items-center justify-between;
}

header .desktop[role=menu] {
  @apply flex shrink-0 justify-between w-full px-10 sm:px-6 lg:px-8;

  > .menu-component > .menu-list {
    @apply hidden lg:flex lg:justify-center;
  }

  & a {
    @apply no-underline hover:underline decoration-pink-600 [&.active]:text-pink-600 [&.active]:underline;
  }
}

header nav.mobile.menu-component {
  @apply w-full h-full overflow-hidden px-10;

  > ul.menu-list {
    @apply pt-4 pb-2 font-body flex flex-col items-end gap-y-4;
    & a:not(.shared-button) {
      @apply flex items-center space-x-2 rounded text-sm font-medium no-underline hover:underline decoration-pink-600 [&.active]:text-pink-600 [&.active]:underline ;
    }
  }
}