.hero {
  @apply relative lg:flex lg:justify-between;
  @apply w-full h-full;
  @apply pt-16 lg:pt-24 !pl-[15%] lg:!pl-16 x-padded pb-36;
  @apply overflow-y-clip overflow-x-visible;
  > .wrapper {
    @apply relative w-[258px] md:w-[416px] h-[217px] md:h-[333px] xl:w-[516px] mb-16;
  }
}
.hero {
  #you-are-here {
    @apply absolute bottom-0;
    @apply right-[13%] sm:right-[18%] md:right-[26%] lg:right-[45%];
    @apply h-[65%] w-px;
    @apply border-2 border-black bg-black;

    svg {
      @apply w-[60px] h-[72px] md:w-[100px] md:h-[120px];
      color: var(--base-bg-selected);
    }
    span.description {
      @apply block text-[16px] font-body whitespace-nowrap mt-3 mb-5;
    }
    span.endpoint {
      @apply bg-black rounded-full w-3 h-3;
    }
    > .wrapper {
      @apply absolute flex flex-col items-center justify-between -translate-x-1/2 -translate-y-full mt-2;
    }
  }
}