.font-display {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.25;
}


h1 {
  @apply font-display text-5xl uppercase;
  margin: 1rem 0 3rem;
  text-align: center;
  font-weight: 900;
  color: var(--heading-color);
  line-height: 1.2;
}

h2 {
  font-size: 1.75rem;
  font-weight: 800;
  line-height: 1.25;

  @media (min-width: 1024px) {
    font-size: 1.875rem;
  }
}

h3 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;

  @media (min-width: 1024px) {
    font-size: 1.5rem;
  }
}

h4 {
	font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
}

h5 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5;
}

h6 {
  font-weight: 700;
}

.link {
  color: var(--text-primary);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  text-decoration-line: underline;

  &:hover,
  &:focus {
    color: var(--text-primary-hover);
  }
}