:root {
  /* Theme colors */
  --color-white: #ffffff;
  --color-black: #000000;

  --color-cloud-50: #FAFDFF;
  --color-cloud-100: #F2F9FF;
  --color-cloud-150: #D9EEFF;
  --color-cloud-200: #B0DBFF;
  --color-cloud-300: #8ACAFF;
  --color-cloud-400: #59B4FF;
  --color-cloud-500: #3BA0F2;
  --color-cloud-600: #3389D0;
  --color-cloud-700: #2B73AD;
  --color-cloud-800: #175587;
  --color-cloud-900: #0F3859;
  
  --color-taffy-50: #FCF7FA;
  --color-taffy-100: #FCF7FA;
  --color-taffy-150: #FCE1EE;
  --color-taffy-200: #FAC8DF;
  --color-taffy-300: #F5B5D3;
  --color-taffy-400: #F089B8;
  --color-taffy-500: #F0519B;
  --color-taffy-600: #CB4583;
  --color-taffy-700: #A82A64;
  --color-taffy-800: #751340;
  --color-taffy-900: #470725;

  --color-butter-100: #FFFFFA;
  --color-butter-200: #FFFBF0;
  --color-butter-300: #FFF2CC;
  --color-butter-400: #FFE185;
  --color-butter-500: #FFC414;

  --color-smoke-50: #fbfaff;
  --color-smoke-100: #f4f2f7;
  --color-smoke-200: #efedf5;
  --color-smoke-300: #dcdae3;
  --color-smoke-400: #c2bfc9;
  --color-smoke-500: #9995a1;
  --color-smoke-600: #716e7a;
  --color-smoke-700: #56525e;
  --color-smoke-800: #3b3745;
  --color-smoke-900: #221e2e;
  --color-smoke-950: #06030e;

  /*Primary*/
  --bg-primary: var(--color-butter-100);
  --bg-primary-hover: var(--color-butter-100);
  --text-on-primary: var(--color-black);
  --border-primary: var(--color-taffy-600);
  --border-primary-hover: var(--color-taffy-400);
  --text-primary: var(--color-black);
  --text-primary-hover: var(--color-taffy-600);
  --logo-primary: var(--color-black);

  /*Secondary*/
  --bg-secondary: var(--color-butter-300);
  --text-on-secondary: var(--color-black);
  --bg-secondary-hover: var(--color-butter-400);
  --text-on-secondary-hover: var(--color-black);
  --border-secondary: var(--color-taffy-400);

  /*Danger*/
  --bg-danger: var(--color-red-700);
  --bg-danger-hover: var(--color-red-600);
  --text-on-danger: var(--color-white);
  --border-danger: var(--color-red-500);
  --border-danger-focus: var(--color-red-500);
  --text-danger: var(--color-red-700);
  --bg-danger-secondary: var(--color-red-50);
  --text-on-danger-secondary: var(--color-red-700);
  --icon-on-danger-secondary: var(--color-red-600);

  /*Success*/
  --bg-success: var(--color-green-800);
  --bg-success-hover: var(--color-green-700);
  --text-on-success: var(--color-white);
  --border-success: var(--color-green-600);
  --border-success-focus: var(--color-green-500);
  --text-success: var(--color-green-800);
  --bg-success-secondary: var(--color-green-50);
  --text-on-success-secondary: var(--color-green-700);
  --icon-on-success-secondary: var(--color-green-600);

  /*Info*/
  --bg-info-secondary: var(--color-cloud-50);
  --text-on-info-secondary: var(--color-cloud-700);
  --icon-on-info-secondary: var(--color-cloud-500);

  /*Warning*/
  --bg-warning-secondary: var(--color-yellow-100);
  --text-on-warning-secondary: var(--color-yellow-500);
  --icon-on-warning-secondary: var(--color-yellow-500);

  /*Accent*/
  --bg-accent: var(--color-orange-700);
  --text-on-accent: var(--color-white);
  --border-accent: var(--color-orange-500);
  --text-accent: var(--color-orange-700);

  /*Light*/
  --bg-light: var(--color-white);
  --bg-light-hover: var(--color-smoke-100);
  --text-on-light: var(--color-smoke-800);
  --text-on-light-outline: var(--color-smoke-800);
  --border-light: var(--color-smoke-300);

  /*Dark*/
  --bg-dark: var(--color-black);
  --bg-dark-secondary: var(--color-smoke-400);
  --bg-dark-hover: var(--color-smoke-900);
  --text-on-dark: var(--color-white);
  --text-on-dark-outline: var(--color-smoke-200);
  --border-dark: var(--color-smoke-700);
  --border-dark-focus: var(--color-smoke-500);

  /*Disabled*/
  --bg-disabled: var(--color-smoke-200);
  --bg-disabled-selected: var(--color-smoke-400);
  --text-on-disabled-selected: var(--color-smoke-200);
  --border-disabled: var(--color-smoke-300);
  --bg-disabled-secondary: var(--color-smoke-300);
  --icon-disabled: var(--color-smoke-400);

  /*Base*/
  --base-text: var(--color-black);
  --base-text-secondary: var(--color-smoke-900);
  --base-text-tertiary: var(--color-smoke-700);
  --base-text-disabled: var(--color-smoke-500);
  --base-icon: var(--color-smoke-600);
  --base-bg-backdrop: var(--color-smoke-800);
  --base-bg-highest: var(--color-butter-300);
  --base-bg-high: var(--color-smoke-200);
  --base-bg-base: var(--color-butter-100);
  --base-bg-low: var(--color-butter-200);
  --base-bg-lowest: var(--color-butter-300);
  --base-border-primary: var(--color-smoke-500);
  --base-border-secondary: var(--color-smoke-400);
  --base-border-tertiary: var(--color-smoke-300);
  --base-border-focus: var(--color-taffy-400);
  --base-bg-selected: var(--color-taffy-600);
  --base-bg-hover: var(--color-butter-100);
  --base-bg-tooltip: var(--color-smoke-800);
  --base-text-on-tooltip: var(--color-smoke-50);
}