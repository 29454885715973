.sponsors_page {
  .sponsors {
    @apply flex flex-col gap-8;

    > .sponsor {
      @apply border-2 w-full mx-auto;
      display: flex;
      align-items: center;
      background-color: var(--base-bg-base);
      min-height: 172px;
      min-width: 200px;

      > .details {
        width: 100%;

        > img {
          @apply mx-auto;
        }
      }
    }

    @media (min-width: 835px) {
      & {
        @apply flex flex-row flex-wrap;

        > .sponsor {
          min-height: 200px;
          width: 327px;
        }
      }
    }

    @media (min-width: 1024px) {
      & {
        > .sponsor {
          min-height: 300px;
          width: 300px;
        }
      }
    }
  }

  div#ruby {
    @apply mt-40 md:mt-16 lg:mt-0;

    .sponsors .sponsor {
      min-height: 300px;
    }

    @media (min-width: 1024px) {
      .sponsors {
        & {
          > .sponsor {
            min-height: 300px;
            width: 500px;
          }
        }
      }
    }
  }
  div#gold {
    background-color: var(--color-butter-500);
    .sponsor {
      border-color: var(--color-black);
    }
  }
  div#silver {
    background-color: var(--color-cloud-150);
  }
  div#bronze {
    background-color: var(--color-taffy-300);
    .sponsor {
      border-color: var(--color-black);
    }
  }
  div#other {
    background-color: var(--color-butter-300);
  }
  div#supporters {
    background-color: var(--color-cloud-900);
    color: var(--base-bg-base);
    h1 {
      color: var(--base-bg-base) !important;
    }
  }
}